import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils';

let form_options = {}
form_options.environments = []
form_options.platforms = []

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.environments,
    form_options.platforms,
  ].forEach(arr => arr.length = 0)

  kioskUtils.getKioskEnvOptions().then(({ envOptions, platformOptions }) => {
    envOptions.forEach(option => form_options.environments.push(option))
    platformOptions.forEach(option => form_options.platforms.push(option))
  })
}


// 正则表达式，用于匹配数字，最大999999.99，最小0，小数点后最多两位
const num_reg = /^\s*(([1-9]\d{0,5})|0)(\.\d{1,2})?\s*$/

export const availableShowsOptions = [
  {
    text: common.getI18n('kiosk.temperature'),
    value: 'isShowBodyTemp',
  },
  {
    text: common.getI18n('kiosk.blood_pressure'),
    value: 'isShowBP',
  },
  {
    text: common.getI18n('kiosk.oximetry'),
    value: 'isShowSpO2',
  },
  {
    text: common.getI18n('kiosk.weight'),
    value: 'isShowWeight',
  },
  {
    text: common.getI18n('kiosk.height'),
    value: 'isShowHeight',
  },
  {
    text: common.getI18n('kiosk.blood_glucose'),
    value: 'isShowGlucose',
  },
  {
    text: common.getI18n('kiosk.grip_strength'),
    value: 'isShowGripStrength',
  },
  {
    text: common.getI18n('kiosk.cognizance'),
    value: 'isShowCognition',
  },
]

export const availableLoginOptions = [
  {
    text: 'FaceID',
    value: 'isLoginFaceID',
  },
  {
    text: 'QRCode',
    value: 'isLoginQrCode',
  },
  {
    text: 'NFC',
    value: 'isLoginNFC',
  },
]

export default [
  {
    name: 'baseInfo.kioskToken',
    label: 'kiosk.device_token',
    type: 'text',
    rule: { required: true, regex: /^\s*.{1,50}\s*$/ },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_kioskToken'),
    },
    trim: true,
    edit_disable: true,
    // show_fn: (vm) => vm.isEdit,
  },
  {
    name: 'baseInfo.kioskName',
    label: 'kiosk.device_name',
    type: 'text',
    rule: 'required|max:30',
  },
  {
    name: 'baseInfo.kioskEnvironment',
    label: 'kiosk.device_env',
    type: 'select',
    rule: 'required',
    options: form_options.environments,
  },
  {
    name: 'baseInfo.kioskPlatform',
    label: 'kiosk.device_platform',
    type: 'select',
    rule: 'required',
    options: form_options.platforms,
    edit_disable: true,
  },

  {
    type: 'hr',
  },

  {
    name: '_positiveShows',
    label: 'kiosk.selected_modules',
    type: 'checkboxFlow',
    rule: 'required',
    options: availableShowsOptions,
  },
  {
    name: '_positiveLoginTypes',
    label: 'kiosk.login_types',
    type: 'checkboxFlow',
    rule: 'required',
    options: availableLoginOptions,
  },
  {
    name: 'measureConfig.spO2SampleSize',
    label: 'spO2SampleSize',
    type: 'number',
    placeholder: '',
    append: '小時',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.spO2Range',
    label: 'spO2Range',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.gripStrengthSampleSize',
    label: 'gripStrengthSampleSize',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.gripStrengthBackgroundSampleSize',
    label: 'gripStrengthBackgroundSampleSize',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.gripStrengthRange',
    label: 'gripStrengthRange',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.gripStrengthMinimumIncrease',
    label: 'gripStrengthMinimumIncrease',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.bodyTempMinimum',
    label: 'bodyTempMinimum',
    type: 'number',
    placeholder: '',
    append: '℃',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.bodyTempRange',
    label: 'bodyTempRange',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.bodyTempSampleSize',
    label: 'bodyTempSampleSize',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.heightMinimum',
    label: 'heightMinimum',
    type: 'number',
    placeholder: '',
    append: '厘米',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.heightRange',
    label: 'heightRange',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.heightOffsetM',
    label: 'heightOffsetM',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.heightOffsetC',
    label: 'heightOffsetC',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.heightSampleSize',
    label: 'heightSampleSize',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.heightMeasureDelaySeconds',
    label: 'heightMeasureDelaySeconds',
    type: 'number',
    placeholder: '',
    append: '秒',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.weightMinimum',
    label: 'weightMinimum',
    type: 'number',
    placeholder: '',
    append: '公斤',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.weightRange',
    label: 'weightRange',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.weightOffsetM',
    label: 'weightOffsetM',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.weightOffsetC',
    label: 'weightOffsetC',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.weightSampleSize',
    label: 'weightSampleSize',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.weightMeasureDelaySeconds',
    label: 'weightMeasureDelaySeconds',
    type: 'number',
    placeholder: '',
    append: '秒',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.faceIdentificationInterval',
    label: 'faceIdentificationInterval',
    type: 'number',
    placeholder: '',
    append: '毫秒',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.faceIdConfidenceFilter',
    label: 'faceIdConfidenceFilter',
    type: 'number',
    placeholder: '',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  // {
  //   name: 'measureConfig.faceCollectionID',
  //   label: 'faceCollectionID',
  //   type: 'text',
  //   placeholder: '',
  //   rule: { required: true, regex: /^\s*.{1,100}\s*$/ },
  //   custom_messages: {
  //     regex: common.getI18n('kiosk.vld_err_faceCollectionID'),
  //   },
  //   trim: true,
  // },
  {
    name: 'measureConfig.heightOfSensor',
    label: 'heightOfSensor',
    type: 'number',
    placeholder: '',
    append: '厘米',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
  },
  {
    name: 'measureConfig.welPageWeightTimeSpan',
    label: 'welPageWeightTimeSpan',
    type: 'number',
    placeholder: '',
    append: '毫秒',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
    tooltip: common.getI18n('kiosk.tooltip_welPageWeightTimeSpan'),
  },
  {
    name: 'measureConfig.resultPageDisplayDuration',
    label: 'resultPageDisplayDuration',
    type: 'number',
    placeholder: '',
    append: '毫秒',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
    tooltip: common.getI18n('kiosk.tooltip_resultPageDisplayDuration'),
  },
  {
    name: 'measureConfig.measureSwitchDuration',
    label: 'measureSwitchDuration',
    type: 'number',
    placeholder: '',
    append: '毫秒',
    rule: { required: true, regex: num_reg },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_num_reg'),
    },
    tooltip: common.getI18n('kiosk.tooltip_measureSwitchDuration'),
  },
]
