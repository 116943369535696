<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field
        :is-edit="true"
        :fields="fields"
        :row-data="row_data"
        post-api="/kioskmanage/UpdateKiosk"
        finish-router="kioskEnvironment"
        permission="Kiosk_Menu_Environment"
        @beforeSubmit="beforeSubmit"
      >
      </edit-field>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData, availableShowsOptions, availableLoginOptions } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import { flattenObject, expandObject } from '@/libs/ez-utils';

export default {
  name: 'Edit',
  components: {
    EditFieldHeader,
    EditField,
    fields,
  },

  data() {
    return {
      title: common.getMenuName('kioskEnvironment') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.token,
      fields,
      availableShows: availableShowsOptions.map(option => option.value),
      availableLoginTypes: availableLoginOptions.map(option => option.value),
      row_data: {
        _positiveShows: [],
        _positiveLoginTypes: [],
      },
    }
  },

  created() {
    reloadFieldData()
  },

  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/kioskmanage/GetKiosk?kioskToken=' + this.id)
      .then(res => {
        // Extract the fields starting with 'isShow' in row_data_default as options for _positiveShows
        const modulesAndStatus = Object.entries(res.data.measureConfig).filter(v => /^isShow.+$/.test(v[0]))
        const _positiveShows = modulesAndStatus.filter(v => v[1]).map(v => v[0])

        // Extract the fields starting with 'isLogin' in row_data_default as options for _positiveLoginTypes
        const loginTypes = Object.entries(res.data.measureConfig).filter(v => /^isLogin.+$/.test(v[0]))
        const _positiveLoginTypes = loginTypes.filter(v => v[1]).map(v => v[0])

        // Merge options into row_data
        this.row_data = Object.assign({ _positiveShows, _positiveLoginTypes }, flattenObject(res.data))
      })
  },

  methods: {
    beforeSubmit(callback) {
      const rowData = expandObject(JSON.parse(JSON.stringify(this.row_data)))

      // Convert _positiveShows to isShowXXX
      this.availableShows.forEach(isShowModule => {
        rowData.measureConfig[isShowModule] = this.row_data._positiveShows.includes(isShowModule)
      })
      delete rowData._positiveShows

      // Convert _positiveLoginTypes to isLoginXXX
      this.availableLoginTypes.forEach(loginType => {
        rowData.measureConfig[loginType] = this.row_data._positiveLoginTypes.includes(loginType)
      })
      delete rowData._positiveLoginTypes

      callback(true, rowData)
    },
  },
}
</script>

<style scoped>

</style>
